<template>
  <div class="screen row">
    <div class="offset-2 col-10">
      <div class="display-5">Clients</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Clients',
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
